import React from 'react';
import SearchBox from '../src/components/common/SearchBox';
import UiFooter from '../src/components/common/UiFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import UiHeader from '../src/components/common/UiHeader';
import Head from 'next/head';
import { GetStaticProps } from 'next';
import { getTranslationProps, initTranslationSSR } from '../src/translation/translation-ssr';
import { useTranslation } from '../src/translation/translations';
import { initTranslationBrowser } from '../src/translation/translation-browser';
import { useRouter } from 'next/router';
import { NewsItem } from '../src/components/stock/section/SectionCompanyInfoNews';
import { RssApiService } from '../src/services/api/rss.api.service';
import { CompanyService } from '../src/services/company.service';
import { CDN_ADDRESS } from '../src/server_address';
import LoadingComponent from '../src/components/common/LoadingComponent';
import { useNews } from '../src/components/stock/useNews';
import { noDegiro } from '../src/components/ads/DegiroAdSlot';
import { LazyLoadingWrapper } from '../src/components/stock/lazy-loading';

const PAGE_SIZE = 35;

const seekingAlphaLinkGenerator = (originalUrl) =>
  'https://www.sahg6dtr.com/Z6FDGZ/R74QP/?url=' +
  encodeURIComponent(
    originalUrl +
      '?source=affiliate_program:{affiliate_name}&utm_medium=affiliate&utm_source={affiliate_name}&affid={affiliate_id}&oid={offer_id}&transaction={transaction_id}',
  );

const HuBlog = ({ initialNewsFeed, t }) => {
  const { pagination, paginationDisabled, hasNext, hasPrev, prevPage, nextPage, rssTopRef } = useNews({
    PAGE_SIZE: 35,
    initialNewsFeed,
    getItems: async (skip, limit, locale) =>
      await RssApiService.getItemsByUrlForWatchlist('https://iocharts.io/blog/feed/', skip, limit),
  });

  return (
    <div className={'section-submodule-content'}>
      <div ref={rssTopRef}></div>
      {paginationDisabled && (
        <div className="home-news-loading-overlay">
          <LoadingComponent />
        </div>
      )}
      <div className={'rss-items-container' + (paginationDisabled ? ' home-news-loading' : '')}>
        {pagination.items?.map((d, i) => <NewsItem key={i} data={d} />)}
      </div>
      {(hasPrev || hasNext) && (
        <div className="section-submodule-tabs">
          {hasPrev && (
            <button className="submodule-tabs-span" disabled={paginationDisabled} onClick={() => prevPage()}>
              {t('picker-previouspage')}
            </button>
          )}
          {hasNext && (
            <button className="submodule-tabs-span" disabled={paginationDisabled} onClick={() => nextPage()}>
              {t('picker-nextpage')}
            </button>
          )}
        </div>
      )}
      <div className="section-submodule-tabs">
        <a href="https://iocharts.io/blog" className="submodule-tabs-span">
          Tovább a blogra
        </a>
      </div>
    </div>
  );
};

const News = ({ initialNewsFeed, t }) => {
  const { pagination, paginationDisabled, hasNext, hasPrev, prevPage, nextPage, rssTopRef } = useNews({
    PAGE_SIZE: 35,
    initialNewsFeed,
    getItems: async (skip, limit, locale) => await RssApiService.getHomeItems(skip, limit, locale),
  });
  return (
    <div className={'section-submodule-content'}>
      {paginationDisabled && (
        <div className="home-news-loading-overlay">
          <LoadingComponent />
        </div>
      )}
      <div className={'rss-items-container' + (paginationDisabled ? ' home-news-loading' : '')}>
        {pagination.items?.map((d, i) => (
          <NewsItem
            oldVersion={true}
            key={i}
            data={d}
            linkGenerator={d.link?.startsWith('https://seekingalpha.com') ? seekingAlphaLinkGenerator : null}
            seekingAlpha={d.link?.startsWith('https://seekingalpha.com')}
          />
        ))}
      </div>
      {(hasPrev || hasNext) && (
        <div className="section-submodule-tabs news-navigation-buttons">
          {hasPrev && (
            <button className="submodule-tabs-span" disabled={paginationDisabled} onClick={() => prevPage()}>
              {t('picker-previouspage')}
            </button>
          )}
          {hasNext && (
            <button className="submodule-tabs-span" disabled={paginationDisabled} onClick={() => nextPage()}>
              {t('picker-nextpage')}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const Home = ({ initialNewsFeed, initialNewsFeedBlog, homeCompanyDetails, ...props }) => {
  initTranslationBrowser();

  const { t } = useTranslation();
  const router = useRouter();
  const locale = router.locale;

  const organizationJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://iocharts.io',
    logo: 'https://iocharts-data.fra1.digitaloceanspaces.com/logo_no_text.png',
  };
  const stringOrganizationJson = JSON.stringify(organizationJson);

  /*
  const [pagination,setPagination] = useState({items: initialNewsFeed.items, initial: true, skip: 0, limit: PAGE_SIZE, length: initialNewsFeed.length})
  const [paginationDisabled,setPaginationDisabled] = useState(false)
  const hasNext = pagination.length > pagination.skip+pagination.limit
  const hasPrev = pagination.skip > 0
   */

  /*


  const rssTopRef = useRef<HTMLDivElement>();

  async function nextPage() {
    const hasNext = pagination.length > pagination.skip+pagination.limit
    if (!hasNext) return
    const skip = pagination.skip + PAGE_SIZE
    setTimeout(() => rssTopRef.current.scrollIntoView({block: "start", inline: "nearest", behavior: "auto"}),1)
    const items = await loadItems(skip,pagination.limit)
    if (items) {
      setPagination({...pagination, items, skip, initial: false})
    }
  }


  async function prevPage() {
    const hasPrev = pagination.skip > 0
    if (!hasPrev) return
    const skip = pagination.skip - PAGE_SIZE
    setTimeout(() => rssTopRef.current.scrollIntoView({block: "start", inline: "nearest", behavior: "auto"}),1)
    const items = await loadItems(skip,pagination.limit)
    if (items) {
      setPagination({...pagination, items, skip, initial: false})
    }

  }

  async function loadItems(skip,limit) {
    try {
      setPaginationDisabled(true)
      const homeItems = await RssApiService.getHomeItems(skip, limit, locale)
      return homeItems.items;
    } finally {
      setPaginationDisabled(false)
    }
  }

  useEffect(() => {
    (async() => {
      if (pagination.initial) return

    })()
  },[pagination])
   */

  return (
    <div className="new-index">
      <Head>
        <title>{t('title-main')}</title>
        <meta charSet="utf-8" />
        <meta name="Description" content={t('meta-main')} />
        <meta property="og:title" content={t('title-main')} />
        <meta property="og:description" content={t('meta-main')} />
        <meta property="og:image" content="https://iocharts-data.fra1.digitaloceanspaces.com/social_share.png" />
        <meta property="og:url" content={'https://iocharts.io' + (router.locale === 'hu' ? '/hu' : '')} />
        <meta property="og:site_name" content="iO Charts" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image:alt" content={t('title-main')} />
        <meta name="twitter:site" content="@iOCharts" />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: stringOrganizationJson }}></script>
      </Head>
      <UiHeader isHomepage={true} />
      <div className="new-index-body home-tagline">
        <div className="tagline-container new-index-body-container">
          <h1 className="tagline">{t('main-quote')}</h1>
        </div>
      </div>
      <div className="new-index-body home-search">
        <div className="new-index-body-container new-index-search-bar-container">
          <div className="new-index-search-bar-container-inner">
            <SearchBox />
          </div>
        </div>
      </div>

      {!noDegiro.includes(locale) && (
        <div className="new-index-body home-adslot">
          <div className="new-index-body-container">{/* <DegiroAdSlot version={0} /> */}</div>
        </div>
      )}

      <div className="new-index-body home-buttons">
        <div className="new-index-body-container">
          <div className="new-index-body-inner-container">
            <Link href={t('/portfolio')} key={1}>
              <a className="index-tile">
                <span className="index-item-icon">
                  <FontAwesomeIcon icon="eye" width="66" height="66" />
                </span>
                <span className="title">{t('portfolio-main')}</span>
              </a>
            </Link>
            <Link href={t('/screener')} key={2}>
              <a className="index-tile">
                <span className="index-item-icon">
                  <FontAwesomeIcon icon="bars" width="66" height="66" />
                </span>
                <span className="title">{t('screener-main')}</span>
              </a>
            </Link>
            {/*<Link href="/green" key={3}>
              <a className="index-tile-green">
                <span className="index-item-icon">
                  <FontAwesomeIcon icon="tree" width="66" height="66" />
                </span>
                <span className="title">{t('green-main')}</span>
              </a>
            </Link>*/}
          </div>
        </div>
      </div>

      <div className="new-index-body home-rss">
        <div className="popular-stocks-and-news-container new-index-body-container">
          <div className="section-company-info-youtube home-news home-news-container" style={{ marginBottom: '20px' }}>
            {locale === 'hu' && (
              <div className="home-news-section">
                <div className="section-header info home-section-header">Blog</div>
                <div className="section-submodule-with-tabs">
                  <HuBlog initialNewsFeed={initialNewsFeedBlog} t={t} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
              </div>
            )}
            <div className="home-news-section">
              <div className="section-header info home-section-header">{t('main-news-title')}</div>
              <div className="section-submodule-with-tabs">
                {![].includes(locale) && (
                  <div>
                    <p className={'seekingalpha-partner-notice'}>iO Charts is a Seeking Alpha partner</p>
                  </div>
                )}
                <News initialNewsFeed={initialNewsFeed} t={t} />
              </div>
            </div>
          </div>
          <div className="section-company-info-youtube home-stocks">
            <div className="section-header info home-section-header">{t('main-popular-stocks')}</div>
            <div className="section-submodule-with-tabs">
              <div className="section-submodule-content">
                {homeCompanyDetails &&
                  homeCompanyDetails.map((d, i) => (
                    <div className={'home-stocks-item' + (d.ticker.endsWith('BUD') ? ' stocks-item-bud' : '')} key={i}>
                      <LazyLoadingWrapper
                        enabled={true}
                        render={(lazy, lazyRef) => (
                          <div
                            ref={lazyRef}
                            style={{
                              backgroundImage: lazy
                                ? null
                                : 'url("' + CDN_ADDRESS + 'logos/' + d.ticker + '.png' + '")',
                            }}
                            className="ticker-logo"
                          />
                        )}
                      />
                      <div>
                        <div className="rss-item-title home-stocks-item-title">
                          <Link href={(locale !== 'en' ? '/' + locale + t('/stocks') + '/' : '/stocks/') + d.ticker}>
                            <a>{d.ticker}</a>
                          </Link>
                          <div className="stock-price">
                            {d.currentAdjClose ? (d.currentAdjClose / 10_000).toFixed(2) : '-'}
                          </div>
                          <div className={d.priceChangepctPrevclose < 0 ? 'stock-movement-down' : 'stock-movement-up'}>
                            ({d.priceChangepctPrevclose > 0 ? '+' : ''}
                            {d.priceChangepctPrevclose ? (d.priceChangepctPrevclose / 10_000).toFixed(2) : '-'}%)
                          </div>
                        </div>
                        <div className="rss-item-description home-stocks-item-description">{d.companyName}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <UiFooter />
    </div>
  );
};
export default Home;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  await initTranslationSSR(locale);

  const homeItems = await RssApiService.getHomeItems(0, PAGE_SIZE, locale);
  const homeItemsBlog = await RssApiService.getItemsByUrlForWatchlist('https://iocharts.io/blog/feed/', 0, PAGE_SIZE);

  // prettier-ignore
  const homeCompanyDetails = await CompanyService.getCompanies(
    locale === 'hu'
      ? ['TSLA', 'OTP.BUD', 'NIO', 'MOL.BUD', 'RICHT.BUD', 'AAPL', 'XPEV', 'OPUS.BUD', '4IG.BUD', 'AMC', 'SPCE', 'PFE', 'AMZN', 'TLRY', 'DIS', 'KO', 'MSFT', 'MTEL.BUD', 'NFLX', 'FB', 'AMD', 'BA', 'GOOGL', 'INTC']
      : locale === 'no'
      ? ['EQNR', 'NAS.OL', 'TSLA', 'DNB.OL', 'SASNO.OL', 'NEL.OL', 'RECSI.OL', 'FREY', 'MPCC.OL', 'YAR.OL', 'NHY.OL', 'AMC', 'FRO', 'ORK.OL', 'PGS.OL', 'KOA.OL', 'TEL.OL', 'VAR.OL', 'AKER.OL', 'AKRBP.OL', 'GOGL', 'FLYR.OL', 'KAHOT.OL', 'NIO', 'AAPL', 'TOM.OL', 'SCATC.OL', 'XPEV', 'KOG.OL']
      : locale === 'da'
        ? ['VWS.CO', 'NVO', 'BAVA.CO', 'DANSKE.CO', 'ORSTED.CO', 'AMBU-B.CO', 'MAERSK-A.CO', 'SAS-DKK.CO', 'TSLA', 'GMAB', 'DSV.CO', 'JYSK.CO', 'ZEAL', 'ISS.CO', 'GN.CO', 'NAS.OL', 'CBRAIN.CO', 'ALMB.CO', 'SYDB.CO', 'FLS.CO', 'ORPH']
      : locale === 'fi'
        ? ['OUT1V.HE', 'FORTUM.HE', 'SAMPO.HE', 'NDA-FI.HE', 'FIA1S.HE', 'TSLA', 'HARVIA.HE', 'NESTE.HE', 'WRT1V.HE', 'TYRES.HE', 'TEM1V.HE', 'QTCOM.HE', 'KNEBV.HE', 'KCR.HE', 'SSABAH.HE', 'UPM.HE', 'TOKMAN.HE', 'PUUILO.HE', 'KAMUX.HE', 'MOCORP.HE', 'VALMT.HE', 'ORNAV.HE']
      : locale === 'sv'
        ? ['VOLCAR-B.ST', 'SBB-B.ST', 'SINCH.ST', 'TSLA', 'ERIC', 'SWED-A.ST', 'NIO', 'HM-B.ST', 'NIBE-B.ST', 'SHOT.ST', 'BOL.ST', 'SAAB-B.ST', 'ONCO.ST', 'CTM.ST', 'NDA-SE.ST', 'SAND.ST', 'SSAB-A.ST', 'EMBRAC-B.ST', 'EVO.ST', 'HTRO.ST', 'TELIA.ST', 'FING-B.ST']
      : locale === 'es'
        ? ['IAG.MC', 'TEF', 'SAB.MC', 'TSLA', 'CABK.MC', 'IBE.MC', 'NIO', 'REP.MC', 'DIA.MC', 'SLR.MC', 'GRFS', 'ELE.MC', 'AIR.MC', 'CLNX.MC', 'MT', 'ACS.MC', 'MEL.MC', 'BKT.MC', 'ACX.MC', 'UNI.MC', 'MAP.MC', 'ENG.MC', 'SOL.MC']
      : locale === 'pl'
        ? ['JSW.WAR', 'CDR.WAR', 'KGH.WAR', 'PKN.WAR', 'PKO.WAR', 'PZU.WAR', 'MAB.WAR', 'PGE.WAR', 'LWB.WAR', 'TPE.WAR', 'MRC.WAR', 'CCC.WAR', 'BMC.WAR', 'BML.WAR', 'DNP.WAR', 'XTB.WAR', 'LBW.WAR', 'LPP.WAR', 'ALR.WAR']
      : locale === 'it'
        ? ['UCG.MI', 'ENEL.MI', 'LFG.MI', 'ISP.MI', 'ENI.MI', 'LDO.MI', 'TIT.MI', 'BPE.MI', 'SPM.MI', 'BGN.MI', 'G.MI', 'JUVE.MI', 'AZM.MI', 'NEXI.MI', 'TIS.MI', 'TRN.MI', 'PST.MI', 'BMED.MI', 'MB.MI', 'A2A.MI', 'UNI.MI']
      : locale === 'fr'
        ? ['FDJ.PA', 'EDF.PA', 'SAF.PA', 'ACA.PA', 'SAN.PA', 'EN.PA', 'SGO.PA', 'AI.PA', 'BNP.PA', 'MC.PA', 'FGR.PA', 'GLE.PA', 'RNO.PA', 'ORP.PA', 'RMS.PA', 'OR.PA', 'CA.PA', 'ALO.PA', 'CS.PA', 'ERA.PA', 'ATO.PA', 'ML.PA', 'AC.TO', 'CGI.TO', 'WEED.TO', 'CGX.TO', 'LIF.TO', 'T.TO']
      : locale === 'pt'
        ? ['BCP.LS', 'EDP.LS', 'TSLA', 'AAPL', 'GALP.LS', 'CTT.LS', 'NVG.LS', 'EDPR.LS', 'PHR.LS', 'ALTR.LS', 'SLBEN.LS', 'EGL.LS', 'NOS.LS', 'OIBR3.SA', 'REP.MC', 'BBAS3.SA', 'MGLU3.SA', 'CVCB3.SA', 'GOL', 'ITSA3.SA', 'CPLE3.SA', 'B3SA3.SA', 'IRBR3.SA', 'SAPR3.SA', 'CIEL3.SA']
      : locale === 'nl'
        ? ['BAMNB.AS', 'ABN.AS', 'SLIGR.AS', 'PNL.AS', 'FUR.AS', 'KPN.AS', 'BESI.AS', 'DSM.AS', 'RAND.AS', 'RNO.PA', 'CS.PA', 'AJAX.AS', 'HEIJM.AS', 'COLR.BR', 'AGS.BR', 'ASRNL.AS', 'SOLB.BR', 'MITRA.BR', 'UCB.BR', 'PROX.BR', 'UMI.BR', 'BEKB.BR']
      : locale === 'de'
        ? ['TSLA', 'BAS.F', 'LHA.F', 'TUI1.F', 'ALV.F', 'DTG.F', 'RHM.F', 'BAYN.F', 'ENR.F', 'SHL.F', 'SIE.F', 'DB', 'VOW.F', 'ROSE.SW', 'HOLN.SW', 'IDIA.SW', 'LONN.SW', 'SIKA.SW', 'BANB.SW', 'SANN.SW']
        : ['TSLA', 'NIO', 'AAPL', 'VZ', 'LAC', 'JNJ', 'INTC', 'ENB', 'DIS', 'ABBV', 'MMM', 'MSFT', 'AMZN', 'AMD', 'NVDA', 'V', 'FB', 'LMT', 'T', 'CSCO', 'IRM', 'BMY', 'WEC', 'DPZ', 'O', 'BRK-B', 'KO', 'PEP', 'PFE', 'NFLX', 'PM', 'MO', 'SPG', 'GOOGL', 'WBA', 'MCD', 'SBUX', 'HD', 'ENPH', 'SEDG', 'SPWR', 'CSIQ', 'JKS', 'OPRA'],
  );

  return {
    props: {
      ...(await getTranslationProps(locale)),
      homeCompanyDetails,
      initialNewsFeed: homeItems,
      initialNewsFeedBlog: homeItemsBlog,
    },
  };
};
