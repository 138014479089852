import { ApiRoutes } from "../../constants/api-routes.const";

export class RssApiService {

  public static async getItemsByUrl(url: string): Promise<RssItemResponse[] & any> {
    try {
      if (!url) {
        throw new Error("Invalid RSS URL:" + url)
        return new Promise(resolve => resolve([]))
      }
      const res = await fetch(ApiRoutes.Company.rss(url), { headers: { "Content-Type": "application/json" } })
      if (res.ok) {
        const body = await res.json()
        return body.items || []
      } else {
        throw new Error(`Couldn't fetch ${res.url}, status: ${res.status}`)
      }
    } catch (e) {
      console.error(e)
      return []
    }
  }

  public static async getItemsByUrlForWatchlist(url: string, skip, limit): Promise<RssItemResponse[] & any> {
    try {
      if (!url) {
        throw new Error("Invalid RSS URL:" + url)
        return new Promise(resolve => resolve([]))
      }
      const res = await fetch(ApiRoutes.Company.rssWithPagination(url, skip, limit), { headers: { "Content-Type": "application/json" } })
      if (res.ok) {
        const body = await res.json()
        return body || { items: [], length: 0 }
      } else {
        throw new Error(`Couldn't fetch ${res.url}, status: ${res.status}`)
      }
    } catch (e) {
      console.error(e)
      return []
    }
  }


  public static async getHomeItems(skip: number, limit: number, locale: string): Promise<RssItemResponse[] & any> {
    try {
      const res = await fetch(ApiRoutes.Company.rssHome(skip, limit, locale), { headers: { "Content-Type": "application/json" } })
      if (res.ok) {
        const body = await res.json()
        return body || { items: [], length: 0 }
      } else {
        throw new Error(`Couldn't fetch ${res.url}, status: ${res.status}`)
      }
    } catch (e) {
      console.error(e)
      return []
    }
  }

}

interface RssResponse {
  items: RssItemResponse[];
  image: RssImageResponse;
  title: string;
  description: string;
  link: string;
  language: string;
  copyright: string;
  lastBuildDate: string;
}

interface RssImageResponse {
  link: string;
  url: string;
  title: string;
  width: string;
  height: string;
}

export interface RssItemResponse {
  title: string;
  link: string;
  pubDate: string;
  content: string;
  contentSnippet: string;
  guid: string;
  isoDate: string;
}
